import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";
// Function to retrieve token from local storage
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
};

// Async thunk to add a product
export const adminProduct = createAsyncThunk(
    "admin/add/product", // Unique action type for adminProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to add product
            const formData = new FormData();
            formData.append('image', data.image);
            formData.append('name', data.name);
            formData.append('price', data.price);
            formData.append('discount', data.discount);
            formData.append('category', data.category);
            formData.append('type', data.type);
            formData.append('description', data.description);
            formData.append('colorName', data.colorName);
            formData.append('quantity', data.qty);
            const res = await fetch(`${apiUrl}admin/product/add`, {
                method: "POST",
                headers: {
                    // Remove Content-Type header or set it to 'multipart/form-data'
                    Authorization: `Bearer ${token}`,
                },
                body: formData, // Send FormData directly as the body
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.errors, "failed");
            }
            // Return data
            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            console.error("Error adding product:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);


// Async thunk to get products
export const getProduct = createAsyncThunk(
    "admin/get/product", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/product`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.data.status) {
                alert(final.data.message);
            }

            // Return data
            return final.data;
        } catch (error) {
            // Handle errors
            console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);
// 3
export const editProduct = createAsyncThunk(
    "admin/editProduct", // Unique action type for adminProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to add product
            const formData = new FormData();
            formData.append('id', data.id);
            formData.append('image', data.image);
            formData.append('name', data.name);
            formData.append('price', data.price);
            formData.append('discount', data.discount);
            formData.append('category', data.category);
            formData.append('type', data.type);
            formData.append('quantity', data.qty);
            const res = await fetch(`${apiUrl}admin/product/update`, {
                method: "POST",
                headers: {
                    // Remove Content-Type header or set it to 'multipart/form-data'
                    Authorization: `Bearer ${token}`,
                },
                body: formData, // Send FormData directly as the body
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.errors, "failed");
            }
            // Return data
            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            console.error("Error adding product:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);


// Define product slice
const ProductSlice = createSlice({
    name: "product",
    initialState: {
        productData: [],
        isLoader: false,
        isError: false,
        productStatus: 'pending',
    },
    extraReducers: (builder) => {
        // Reducers for adminProduct async thunk
        builder
            .addCase(adminProduct.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(adminProduct.fulfilled, (state, action) => {
                state.isLoader = false;
                state.productStatus = 'success';
                state.productData = action.payload;
            })
            .addCase(adminProduct.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });

        // Reducers for getProduct async thunk
        builder
            .addCase(getProduct.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.isLoader = false;
                state.productStatus = 'success';
                state.productData = action.payload;
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
            // 3
            builder
            .addCase(editProduct.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(editProduct.fulfilled, (state, action) => {
                state.isLoader = false;
                state.productData = action.payload;
            })
            .addCase(editProduct.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});

// Export product reducer
export default ProductSlice.reducer;
