import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "./apiConfig";
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
  };
export const fetchProfile = createAsyncThunk(
  "admin/login",
  async (credentials) => {
    console.log(credentials);
    const token = await getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    if (final.data.status) {
      alert(final.data.message);
    }
    return final.data;
  }
);
const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileData: [],
    isLoader: false,
    isError: false,
    profileStatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profileStatus = "success";
      state.isLoader = false;
      state.profileData = action.payload;
    });
    builder.addCase(fetchProfile.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});
export default profileSlice.reducer;
