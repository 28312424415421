import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";
// Function to retrieve token from local storage
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
};
// 1
export const addOffers = createAsyncThunk(
    "admin/addOffers", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            const formData = new FormData();
            formData.append('image', data.image);
            formData.append('name', data.name);
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/offers/add`, {
                method: "POST",
                headers: {
                    // "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    // Accept: "*/*",
                },
                body: formData,
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.erors, "failed");
            }

            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            // console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);
// 2
export const getOffers = createAsyncThunk(
    "admin/getOffers", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/offers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                // body: JSON.stringify(data), 
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.data.status) {
                alert(final.data.message);
            }

            // Return data
            return final.data;
        } catch (error) {
            // Handle errors
            console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);

const AdminOffersSlice = createSlice({
    name: "categoery",
    initialState: {
        offerData: [],
        isLoader: false,
        isError: false,
        offerStatus: 'pending',
    },
    extraReducers: (builder) => {
        builder
            .addCase(addOffers.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(addOffers.fulfilled, (state, action) => {
                state.offerStatus = 'success';
                state.isLoader = false;
                state.offerData = action.payload;
            })
            .addCase(addOffers.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
        // 2 getCategory
        builder
            .addCase(getOffers.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(getOffers.fulfilled, (state, action) => {
                state.offerStatus = 'success';
                state.isLoader = false;
                state.offerData = action.payload;
            })
            .addCase(getOffers.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});

// Export product reducer
export default AdminOffersSlice.reducer;
