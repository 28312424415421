import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./AdminLayout";
import { getColor } from "../redux/Slice/Admin/AdminColorSlice";
import { Link } from "react-router-dom";
const AdminColor = () => {
    const dispatch = useDispatch();
    const { colorData, colorStatus } = useSelector((state) => state.color);
    useEffect(() => {
        dispatch(getColor());
    }, [colorData])
    console.log(colorData);
    const EditAdminCategory = (data, e) => {
        // e.preventDefault();
        // const queryParams = new URLSearchParams(data).toString();
        // navigate(`/admin/edit/category?${queryParams}`);
    }
    return (
        <AdminLayout>
            <div className="card mt-3">
                <div className="card-header">
                    <Link
                        to="/admin/color/add"
                        className="card rounded-md bg-blue-600 px-3 p-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 float-end mb-2"
                    >
                        Add Color
                    </Link>
                </div>
                <div className="card-body">
                    <table className=" productTable w-full border border-collapse mt-0">
                        <thead>
                            <tr>
                                <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {colorStatus == 'success' && (
                                colorData.map(product => (
                                    <tr key={product.id}>
                                        <td className="text-center">{product.id}</td>
                                        <td className="text-center">{product.name}</td>
                                        <td className="text-center" onClick={(e) => EditAdminCategory(product, e)}>
                                            <a href="">Edit</a>
                                        </td>
                                    </tr>
                                ))
                            )}

                        </tbody>

                    </table>
                </div>
            </div>
        </AdminLayout>
    )
}
export default AdminColor;