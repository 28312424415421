import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { useDispatch, useSelector } from "react-redux";
import { allproduct } from "../../../redux/Slice/Seller/ProductListSlice";
import { useEffect } from "react";
import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
} from "../../../assets/images/index";

const SpecialOffers = () => {
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.ProductList);
  useEffect(() => {
    dispatch(allproduct());
  }, []);

  return (
    <div className="w-full pb-20">
      <Heading heading="Special Offers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
       {
        data.map(product => (
          product.type == 3 ? (
            <Product
              key={product.id}
              _id={product.id}
              img={product.image}
              productName={product.name}
              price={product.price}
              color={product.color}
              badge={true}
              des={product.description}
            />
          ) : null
        ))}
       
       
       
        {/* <Product
          _id="1101"
          img={spfOne}
          productName="Cap for Boys"
          price="35.00"
          color="Blank and White"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
        {/* <Product
          _id="1102"
          img={spfTwo}
          productName="Tea Table"
          price="180.00"
          color="Gray"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
        {/* <Product
          _id="1103"
          img={spfThree}
          productName="Headphones"
          price="25.00"
          color="Mixed"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
        {/* <Product
          _id="1104"
          img={spfFour}
          productName="Sun glasses"
          price="220.00"
          color="Black"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
      </div>
    </div>
  );
};

export default SpecialOffers;
