import { fetchProfile } from "../redux/Slice/profileSlice";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"; // Importing useDispatch
// import { navigate } from "@reach/router"; // Importing navigate from @reach/router

const AdminRoute = ({ children }) => {
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const { profileData, profileStatus } = useSelector((state) => state.profile);
   // Adding useDispatch hook
    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]); // Adding dispatch to dependency array to fix the warning

    // Checking if profileStatus is 'success' before rendering children
    if (profileStatus == 'success') {
        if (profileData.role_id == 1) {
            return children;
        } else {
            navigate('/admin-login');
        }
    } else {
        // Add a loading state or return null while profile is being fetched
        navigate('/admin-login');
    }
};

export default AdminRoute;
