import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";
// Function to retrieve token from local storage
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
};
// 1
export const addCategory = createAsyncThunk(
    "admin/addCategory", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/product/category/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(data),
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.erors, "failed");
            }

            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            // console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);
// 2
export const getCategory = createAsyncThunk(
    "admin/getCategory", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/product/category`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                // body: JSON.stringify(data), 
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.data.status) {
                alert(final.data.message);
            }

            // Return data
            return final.data;
        } catch (error) {
            // Handle errors
            console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);
// 3
export const updateCategory = createAsyncThunk(
    "admin/updateCategory", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/product/category/update`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(data),
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.erors, "failed");
            }

            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            // console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);
const CategorySlice = createSlice({
    name: "categoery",
    initialState: {
        categoryData: [],
        isLoader: false,
        isError: false,
        categoryStatus: 'pending',
    },
    extraReducers: (builder) => {
        builder
            .addCase(addCategory.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.categoryStatus = 'success';
                state.isLoader = false;
                state.categoryData = action.payload;
            })
            .addCase(addCategory.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
        // 4 getCategory
        builder
            .addCase(getCategory.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.categoryStatus = 'success';
                state.isLoader = false;
                state.categoryData = action.payload;
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
            // 3
            builder
            .addCase(updateCategory.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.categoryStatus = 'success';
                state.isLoader = false;
                state.categoryData = action.payload;
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});

// Export product reducer
export default CategorySlice.reducer;
