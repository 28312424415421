import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";
// Function to retrieve token from local storage
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
};
// 5
export const fetchOrderHistory = createAsyncThunk(
    "admin/addType", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/orders`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(data),
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                // handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.errors);
            }
            // Return data
            return final.data;
        } catch (error) {
            // Handle errors
            handleApiError(error, "failed");
            // console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);

const OrderHistorySlice = createSlice({
    name: "orderHistory",
    initialState: {
        orderHistory: [],
        isLoader: false,
        isError: false,
        historyStatus: 'pending',
    },
    extraReducers: (builder) => {
        //  1
        builder
            .addCase(fetchOrderHistory.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(fetchOrderHistory.fulfilled, (state, action) => {
                state.historyStatus = 'success';
                state.isLoader = false;
                state.orderHistory = action.payload;
            })
            .addCase(fetchOrderHistory.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});

// Export product reducer
export default OrderHistorySlice.reducer;
