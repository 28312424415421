import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

// const getTokenFromLocalStorage = async() => {
//   return localStorage.getItem("token");
// };

export const fetchcategory = createAsyncThunk(
  "fetchColordata",
  async () => {
    console.log("api call");
    // const token = await getTokenFromLocalStorage();
    const res = await fetch(`${apiUrl}category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    const final = await res.json();
    console.log("faa", final);
    return final.data;
  }
);

const SellerCategorySlice = createSlice({
  name: "front_color",
  initialState: {
    cat_data: null,
    isLoader: false,
    isError: false,
    catstatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchcategory.pending, (state) => {
      state.isLoader = true;
      state.catstatus = "pending";
    });
    builder.addCase(fetchcategory.fulfilled, (state, action) => {
      state.isLoader = false;
      state.catstatus = "success";
      state.cat_data = action.payload;
    });
    builder.addCase(fetchcategory.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
      state.cat_data = null;
      state.catstatus = "rejected";
    });
  },
});

export default SellerCategorySlice.reducer;
