import Swal from "sweetalert2";
export const handleApiSuccess = (success, customESucessMessage = 'Successfully.') => {
    if (success) {
        Swal.fire({
            icon: 'success',
            title: success,
            text: success,
            timer: 3000, // Automatically close after 3 seconds
        });
    } else {
        alert(customESucessMessage);
        Swal.fire({
            icon: 'error',
            title: customESucessMessage,
            text: customESucessMessage,
            timer: 3000, // Automatically close after 3 seconds
        });
    }
};