import { useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderHistory } from "../redux/Slice/Admin/OrderHistorySlice";

const AdminOrderHistory = () => {
    const dispatch = useDispatch();
    const { orderHistory, historyStatus } = useSelector((state) => state.adminOders);

    useEffect(() => {
        dispatch(fetchOrderHistory());
    }, []);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { 
            year: 'numeric', 
            month: 'long', // Display month name
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false // Disable AM/PM format
        };
        return date.toLocaleString('en-US', options);
    };
    return (
        <AdminLayout>
            <div className="card mt-3">
                <div className="card-header"></div>
                <div className="card-body">
                    <table className="productTable w-full border border-collapse mt-0">
                        <thead>
                            <tr>
                                <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Product ID</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Total</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Quantity</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Payment Status</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">User ID</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Created At</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyStatus ? (
                                orderHistory.map(order => (
                                    <tr key={order.id}>
                                        <td className="text-center">{order.id}</td>
                                        <td className="text-center">{order.product_id}</td>
                                        <td className="text-center">{order.total}</td>
                                        <td className="text-center">{order.qty}</td>
                                        <td className="text-center">{order.payment_status}</td>
                                        <td className="text-center">{order.user_name}</td>
                                        <td>{formatDate(order.created_at)}</td>
                                        <td>{formatDate(order.updated_at)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">Loading order history...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminLayout>
    );
}

export default AdminOrderHistory;
