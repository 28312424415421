import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import orebiReducer from "./orebiSlice";
import profileSlice from "./Slice/profileSlice";
import ProductSlice from "./Slice/Admin/ProductSlice";
import ProductListSlice from "./Slice/Seller/ProductListSlice";
import CategorySlice from "./Slice/Admin/CategorySlice";
import ProductTypeSlice from "./Slice/Admin/ProductTypeSlice";
import AdminOffersSlice from "./Slice/Admin/AdminOffersSlice";
import BannerSlice from "./Slice/Seller/BannerSlice";
import { signUp } from "./Slice/Seller/AuthSlice";
import OrderHistorySlice from "./Slice/Admin/OrderHistorySlice";
import AdminColorSlice from "./Slice/Admin/AdminColorSlice";
import ColorSlice from "./Slice/Seller/ColorSlice";
import SellerCategorySlice from "./Slice/Seller/SellerCategorySlice";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, orebiReducer);

export const store = configureStore({
  reducer: {
    orebiReducer: persistedReducer,
    profile: profileSlice,
    product: ProductSlice,
    ProductList: ProductListSlice,
    category: CategorySlice,
    type: ProductTypeSlice,
    adminoffers: AdminOffersSlice,
    banner: BannerSlice,
    auth: signUp,
    adminOders: OrderHistorySlice,
    color : AdminColorSlice,
   front_color: ColorSlice,
   seller_category:SellerCategorySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
