import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
const getTokenFromLocalStorage = async() => {
  return localStorage.getItem("token");
};
export const addorder = createAsyncThunk(
  "signUp",
  async (credentials) => {
    const token = await getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}oders/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    // alert(final.message);
    if(final) {
      alert(final.message);
    }
    return final.data;
  }
);
// 2
export const order = createAsyncThunk(
    "signIn",
    async (credentials) => {
      console.log("auth called",credentials);
      const token = await getTokenFromLocalStorage();
  
      const res = await fetch(`${apiUrl}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
        body: JSON.stringify(credentials),
      });
      const final = await res.json();
      if (final.data.status) {
        alert(final.data.message);
      }
      return final.data;
    }
  );


const AuthSlice = createSlice({
  name: "order",
  initialState: {
    orderData: [],
    isLoader: false,
    isError: false,
    orderStatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(addorder.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addorder.fulfilled, (state, action) => {
      state.orderStatus = "success";
      state.isLoader = false;
      state.orderData = action.payload;
    });
    builder.addCase(addorder.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    //  2
    builder.addCase(order.pending, (state) => {
        state.isLoader = true;
        state.isError = false;
      });
      builder.addCase(order.fulfilled, (state, action) => {
        state.orderStatus = "success";
        state.isLoader = false;
        state.orderData = action.payload;
      });
      builder.addCase(order.rejected, (state) => {
        state.isLoader = false;
        state.isError = true;
      });
  },
});
export default AuthSlice.reducer;
