import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { fetchProfile } from "../../Redux/Slice/ProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/Slice/Admin/AdminloginSlice";
const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      const action = await dispatch(loginUser({ email, password }));

      // dispatch(fetchProfile());
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Incorrect User Id Or Password");
    }
    const storedToken = localStorage.getItem("adminToken");

    if (storedToken) {
      navigate("/admin/product");
    } else {
      navigate("/admin-login");
    }
  };
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    width: "100%",
    backgroundImage: `url('https://wallpaperaccess.com/full/2593068.jpg')`,
    backgroundSize: "cover",
    margin: "-10px",
    transition: "background-image 1s ease-in-out",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    width: "90%", // Adjusted width for better responsiveness
    maxWidth: "400px", // Added maximum width
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "20px",
    borderRadius: "8px",
    alignItems: "center",
    color: "white",
  };

  const inputStyle = {
    margin: "10px",
    padding: "12px",
    fontSize: "16px",
    width: "70%",
    color:'black',
  };

  const buttonStyle = {
    margin: "10px",
    padding: "12px",
    fontSize: "18px",
    background: "linear-gradient(to right, #1b2365, #181f5e)",
    color: "white",
    cursor: "pointer",
    width: "77%", // Set width to 100%
  };

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflowX = "hidden";

    // Re-enable scrolling on unmount
    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  return (
    <div style={containerStyle}>
      <form style={formStyle}>
        <h2
          style={{
            color: "#5472d2",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            fontSize: 35,
          }}
        >
          Hi, Welcome Back
        </h2>
        <h3
          style={{
            color: "#8e8e8e",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
          }}
        >
          Enter your credentials to continue
        </h3>
        <h1
          style={{
            color: "#5472d2",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 700,
            marginBottom: "25px",
            fontSize: 30,
          }}
        >
          Login
        </h1>

        <input
          type="text"
          placeholder="User ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <button type="button" onClick={handleLogin} style={buttonStyle}>
          Login
        </button>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default AdminLogin;
